import React from "react";

const Home = () => {
  return (
    <>
      <section className="about_section ">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 className="heading_style">About ISOVIA INC</h2>
              <p>
                We are the team of young aspirants having vast experience in
                Transport sector & IT Sector.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="about_img-box">
                <img src="images/2truck.gif" alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-md-6 about_detail-container">
              <div className="about_detail-box">
                <h3>Arshdeep Singh Chatha – CEO and Founder of Isovia Inc.</h3>
                <p>


                  Since 2015, Arshdeep Singh Chatha has nurtured a vision of transforming the freight industry through advanced technology. His journey began with hands-on experience as a dispatcher and freight broker for various companies, giving him a deep understanding of the logistics sector's challenges and complexities. This experience inspired the creation of Isovia Inc., a company committed to delivering a one-stop, AI-assisted Freight Management System designed to streamline operations for businesses of any scale.
</p><p>
                  At Isovia Inc., Arshdeep leads the development of cutting-edge software solutions that empower companies to optimize supply chain efficiency, enhance productivity, and achieve real-time visibility. With his expertise in software development and logistics, he brings a unique blend of industry insight and technical innovation, making Isovia Inc. a trusted partner for modern freight solutions.
                </p>
                <div className="d-flex  justify-content-end">
                  <a href="" className="quote-btn about-btn">
                    <img src="images/white-next.png" alt="" />
                    <span>About More</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* service section */}
      <section className="service_section layout_padding">
        <div className="container">
          <div className="d-flex justify-content-center">
            <h2 className="heading_style">Our Services</h2>
          </div>
          <div className="row layout_padding2-top">
            <div className="col-md-4">
              <div className="service_img-box s-b-1">
                <img src="images/fleet.png" alt="" />
                <div className="d-flex">
                  <h3>Fleet Management System</h3>
                </div>
              </div>
              <div className="service-detail">
                <p>
                  Our system enables brokers and shippers to manage thier fleets
                  and loads.
                </p>
                <div className="d-flex justify-content-end">
                  <a href="" className="service-btn">
                    See More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="service_img-box s-b-2">
                <img src="images/transportmanagement.png" alt="" />
                <div className="d-flex">
                  <h3>Transport Management System</h3>
                </div>
              </div>
              <div className="service-detail">
                <p>
                  our system helps carriers to save money and time by managing
                  thier all tasks at one platform
                </p>
                <div className="d-flex justify-content-end">
                  <a href="" className="service-btn">
                    See More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="service_img-box s-b-3">
                <img src="images/fuel-monitoring.png" alt="" />
                <div className="d-flex">
                  <h3>Fuel Monitoring System</h3>
                </div>
              </div>
              <div className="service-detail">
                <p>
                  Effectively manage your fuel through our real time fuel
                  tracker
                </p>
                <div className="d-flex justify-content-end">
                  <a href="" className="service-btn">
                    See More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end service section */}
      <section className="shop_section">
        <div className="container">
          <div className="d-flex justify-content-center">
            <h2 className="heading_style">Our Shop</h2>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="d-flex align-items-center h-100">
                <div className="shop-detail">
                  <h5>Everything You Need</h5>
                  <p>
                    Check Driver speed, halts for monetring driver behaviour
                    while driving Narrow down your tracking process with Geo
                    fencing Secure your documents with our system by encrypting
                    it
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="shop_img-box">
                <img src="images/2image.jpg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="client_section layout_padding">
        <div className="d-flex justify-content-center">
          <h2 className="heading_style">Testimonial</h2>
        </div>
        <div
          id="carouselExampleIndicator"
          className="carousel slide"
          data-ride="carousel"
        >
          <ol className="carousel-indicators">
            <li
              data-target="#carouselExampleIndicator"
              data-slide-to={0}
              className="active"
            />
            <li data-target="#carouselExampleIndicator" data-slide-to={1} />
            <li data-target="#carouselExampleIndicator" data-slide-to={2} />
            <li data-target="#carouselExampleIndicator" data-slide-to={3} />
          </ol>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="client_box c-b-1">
                      <div className="client_img">
                        <img
                          src="images/client.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="client_detail">
                        <h5>Rock lu</h5>
                        <p>
                          Contrary to popular belief, Lorem Ipsum is not simply
                          random text. It has roots in a piece of classical
                          Latin literature from 45 BC, making it over 2000 years
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="client_box c-b-2">
                      <div className="client_img">
                        <img
                          src="images/client.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="client_detail">
                        <h5>Rock lu</h5>
                        <p>
                          Contrary to popular belief, Lorem Ipsum is not simply
                          random text. It has roots in a piece of classical
                          Latin literature from 45 BC, making it over 2000 years
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="client_box c-b-1">
                      <div className="client_img">
                        <img
                          src="images/client.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="client_detail">
                        <h5>Rock lu</h5>
                        <p>
                          Contrary to popular belief, Lorem Ipsum is not simply
                          random text. It has roots in a piece of classical
                          Latin literature from 45 BC, making it over 2000 years
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="client_box c-b-2">
                      <div className="client_img">
                        <img
                          src="images/client.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="client_detail">
                        <h5>Rock lu</h5>
                        <p>
                          Contrary to popular belief, Lorem Ipsum is not simply
                          random text. It has roots in a piece of classical
                          Latin literature from 45 BC, making it over 2000 years
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="client_box c-b-1">
                      <div className="client_img">
                        <img
                          src="images/client.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="client_detail">
                        <h5>Rock lu</h5>
                        <p>
                          Contrary to popular belief, Lorem Ipsum is not simply
                          random text. It has roots in a piece of classical
                          Latin literature from 45 BC, making it over 2000 years
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="client_box c-b-2">
                      <div className="client_img">
                        <img
                          src="images/client.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="client_detail">
                        <h5>Rock lu</h5>
                        <p>
                          Contrary to popular belief, Lorem Ipsum is not simply
                          random text. It has roots in a piece of classical
                          Latin literature from 45 BC, making it over 2000 years
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="client_box c-b-1">
                      <div className="client_img">
                        <img
                          src="images/client.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="client_detail">
                        <h5>Rock lu</h5>
                        <p>
                          Contrary to popular belief, Lorem Ipsum is not simply
                          random text. It has roots in a piece of classical
                          Latin literature from 45 BC, making it over 2000 years
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="client_box c-b-2">
                      <div className="client_img">
                        <img
                          src="images/client.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="client_detail">
                        <h5>Rock lu</h5>
                        <p>
                          Contrary to popular belief, Lorem Ipsum is not simply
                          random text. It has roots in a piece of classical
                          Latin literature from 45 BC, making it over 2000 years
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="company_section layout_padding2">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="d-flex align-items-center h-100">
                <div className="company-detail">
                  <h3>Company</h3>
                  <p>
                    Contrary to popular belief, Lorem Ipsum is not simply random
                    text. It has roots in a piece of classical Latin literature
                    from 45 BC, making it over 2000 yearsContrary to popular
                    belief, Lorem Ipsum is not simply random text. It has roots
                    in a piece of classical Latin literature from 45 BC, making
                    it over 2000 years
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="company_img-box">
                <img src="images/company.jpg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact_section layout_padding">
        <div className="d-flex justify-content-center">
          <h2 className="heading_style">Contact us</h2>
        </div>
        <div className="container layout_padding2-top">
          <div className="row">
            <div className="col-md-6">
              {/* <div id="map" className="h-100 w-100" /> */}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2883.987103940619!2d-79.83063792399456!3d43.710817071099505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b1407a332ea77%3A0x2c6bf2883e944c9a!2s33%20Sir%20Jacobs%20Crescent%2C%20Brampton%2C%20ON%20L7A%203V2%2C%20Canada!5e0!3m2!1sen!2sin!4v1711268425694!5m2!1sen!2sin"
                width={600}
                height={450}
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />

            </div>
            <div className="col-md-6">
              <div className="contact_form-container">
                <form action="">
                  <div>
                    <input type="text" placeholder="Your Name" />
                  </div>
                  <div>
                    <input type="email" placeholder="Your Email" />
                  </div>
                  <div>
                    <input type="text" placeholder="Your Phone" />
                  </div>
                  <div>
                    <input
                      type="text"
                      className="message_input"
                      placeholder="Message"
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <button type="submit " className="">
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
